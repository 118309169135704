.preloader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: #f6f2eb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #fec887 !important;
  color: black;
}

.ck-editor__editable {
  min-height: 300px;
}

::-ms-reveal {
  display: none;
}

.btn {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none !important;
  border-radius: 6px;
}
.btn.btn-primary {
  background: #fec887;
  color: black;
  border-color: #fec887;
}
.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus {
  background: #e9942c !important;
  border-color: #e9942c;
}
.btn.btn-outline-primary {
  background: none;
  color: black;
  border-color: #fec887;
}
.btn.btn-outline-primary:hover, .btn.btn-outline-primary:active, .btn.btn-outline-primary:focus {
  background: #fec887 !important;
  border-color: #fec887;
}

#mainBody {
  font-size: 14px;
  padding: 24px;
  position: relative;
}
#mainBody .notification-page-list-item {
  display: flex;
  gap: 20px;
  padding: 8px 16px;
}
#mainBody .notification-page-list-item div:last-child {
  flex-grow: 1;
}
#mainBody .notification-page-list-item:hover {
  background-color: #eef6ff;
}
#mainBody .profile .profile-container {
  display: flex;
  gap: 20px;
}
#mainBody .profile .profile-container .profile-block {
  background-color: white;
  border-radius: 10px;
  width: 300px;
  overflow: hidden;
}
#mainBody .profile .profile-container .profile-block .header {
  padding: 20px;
}
#mainBody .profile .profile-container .profile-block .header .image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
#mainBody .profile .profile-container .profile-block .header .image img {
  border-radius: 50%;
  width: 120px;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
}
#mainBody .profile .profile-container .profile-block .header .image:hover .edit-user-photo {
  display: flex;
}
#mainBody .profile .profile-container .profile-block .header .image .edit-user-photo {
  position: absolute;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #0c70b3;
  background-color: rgba(255, 187, 0, 0.631372549);
  cursor: pointer;
  display: none;
}
#mainBody .profile .profile-container .profile-block .header .info {
  text-align: center;
}
#mainBody .profile .profile-container .profile-block .header .company-info a {
  text-decoration: none !important;
}
#mainBody .profile .profile-container .profile-block .header .company-info .company-block {
  background-color: #f1f1f1;
  border-radius: 6px;
  padding: 8px 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}
#mainBody .profile .profile-container .profile-block .header .company-info .company-block img {
  aspect-ratio: 1/1;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 4px;
  width: 40px;
}
#mainBody .profile .profile-container .profile-block .header .company-info .company-block p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}
#mainBody .profile .profile-container .setting-block {
  background-color: white;
  flex-grow: 1;
  padding: 20px;
}
#mainBody .home-page-cards {
  display: flex;
  gap: 20px;
}
#mainBody .home-page-statistics {
  background: #ffc165;
  border-radius: 16px;
  min-width: 200px;
  padding: 20px;
}
#mainBody .home-page-statistics h5 {
  font-size: 18px;
  color: black;
  font-weight: bold;
}
#mainBody .home-page-banner {
  flex-grow: 1;
  background: white;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
#mainBody .home-page-banner .content {
  position: relative;
  padding: 30px;
}
#mainBody .home-page-banner .content h2 {
  color: rgb(58, 8, 124);
}
#mainBody .home-page-banner img {
  width: 200px;
}
#mainBody .calendar-container {
  border-radius: 10px;
  color: #596780;
}
#mainBody .calendar-container .calendar-container-content {
  display: flex;
}
#mainBody .calendar-container .data-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  flex-direction: column;
  border: 1px solid #eef6ff;
  background-color: white;
}
#mainBody .calendar-container .data-block * {
  padding: 0;
  margin: 0;
}
#mainBody .calendar-container .data-block p {
  color: gray;
}
#mainBody .calendar-container .title-block {
  position: sticky;
  top: -30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  flex-direction: column;
  border: 1px solid #eef6ff;
  z-index: 10;
}
#mainBody .calendar-container .title-block * {
  padding: 0;
  margin: 0;
}
#mainBody .calendar-container .title-block p {
  color: gray;
}
#mainBody .calendar-container .calendar-hours-container {
  width: 100px;
}
#mainBody .calendar-container .calendar-week-container {
  flex-grow: 1;
  display: flex;
}
#mainBody .calendar-container .calendar-week-container .calendar-day-container {
  width: 14.2857142857%;
  position: relative;
}
#mainBody .calendar-container .calendar-week-container .calendar-day-container:nth-child(6) .title-block,
#mainBody .calendar-container .calendar-week-container .calendar-day-container:nth-child(6) .data-block, #mainBody .calendar-container .calendar-week-container .calendar-day-container:nth-child(7) .title-block,
#mainBody .calendar-container .calendar-week-container .calendar-day-container:nth-child(7) .data-block {
  background-color: #fffdfa !important;
}
#mainBody .calendar-container .calendar-week-container .calendar-day-container.active .title-block,
#mainBody .calendar-container .calendar-week-container .calendar-day-container.active .data-block {
  background-color: #f7fbff !important;
}
#mainBody .calendar-container .calendar-week-container .calendar-day-container .calendar-day-content {
  position: absolute;
  height: 100%;
  z-index: 2;
  top: 60px;
  width: 100%;
}
#mainBody .calendar-container .calendar-week-container .calendar-day-container .calendar-day-content .event {
  width: 100%;
  background-color: #fec887;
  border-radius: 6px;
  color: black;
  font-weight: bold;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 100px;
  left: 0;
  min-height: 50px;
  overflow: hidden;
}
#mainBody .calendar-container .calendar-week-container .calendar-day-container .calendar-day-content .event p {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 600px;
}
#mainBody .calendar-container .calendar-week-container .calendar-day-container .calendar-day-content .event:hover {
  background-color: #e9942c;
}
#mainBody .status-badge {
  border: 2px solid;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 12px;
  display: inline-block;
}
#mainBody .status-badge.status-badge-primary {
  border-color: #0c70b3;
  display: inline-block;
  font-weight: bold;
  color: #0c70b3;
}
#mainBody .status-badge.status-badge-warning {
  border-color: #ffbb00;
  display: inline-block;
  font-weight: bold;
  color: #ffbb00;
}
#mainBody .status-badge.status-badge-success {
  border-color: #28a745;
  display: inline-block;
  font-weight: bold;
  color: #28a745;
}
#mainBody .status-badge.status-badge-danger {
  border-color: #dc3545;
  display: inline-block;
  font-weight: bold;
  color: #dc3545;
}
#mainBody .form-control {
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 6px;
}
#mainBody .form-control:focus {
  border-color: #fec887;
  box-shadow: 0 0 0 0.25rem rgba(253, 165, 13, 0.25);
}
#mainBody a {
  color: black !important;
  text-decoration: none;
}
#mainBody a.btn-success {
  color: white !important;
}
#mainBody a:hover {
  text-decoration: underline;
}
#mainBody .filter {
  position: relative;
}
#mainBody .filter .filter-container {
  z-index: 1060;
  position: absolute;
  top: 40px;
  background-color: white;
  right: 0px;
  width: 500px;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #fec887;
}
#mainBody nav {
  background-color: white;
  position: relative;
  z-index: 1000;
}
#mainBody nav .notifications {
  position: relative;
}
#mainBody nav .notifications .messages {
  border: 1px solid #ffbb00;
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: white;
  border-radius: 6px;
  width: 400px;
  overflow: hidden;
}
#mainBody nav .notifications .messages ul {
  padding: 0;
  margin: 0;
  width: 100%;
  flex-direction: column;
  gap: 0;
}
#mainBody nav .notifications .messages ul li {
  padding: 8px 16px;
  border-bottom: 1px solid #eef6ff;
  width: 100%;
}
#mainBody nav .notifications .messages ul li:hover {
  background-color: #eef6ff;
}
#mainBody .root-container {
  border-radius: 20px;
  min-height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);
  overflow: hidden;
  height: calc(100vh - 48px);
  box-shadow: 0 0 30px -10px rgba(37, 37, 37, 0.213);
}
#mainBody .nav-container {
  display: flex;
  padding: 0 30px;
  align-items: center;
}
#mainBody .nav-container a {
  text-decoration: none !important;
}
#mainBody .nav-container .finder {
  flex-grow: 1;
  max-width: 600px;
  position: relative;
}
#mainBody .nav-container .finder .form-control {
  height: 40px;
}
#mainBody .nav-container .finder button {
  position: absolute;
  bottom: 4px;
  right: 4px;
  background: none;
  border: none;
  border-radius: 6px;
  height: 32px;
  width: 32px;
}
#mainBody .nav-container .finder button:hover {
  background: #fec887;
}
#mainBody .nav-container .finder .result {
  position: absolute;
  top: 42px;
  border-radius: 6px;
  width: 100%;
  left: 0px;
  background-color: white;
  padding: 20px;
}
#mainBody .nav-container .finder .result .consts-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
#mainBody .nav-container .finder .result .consts-list .consts-list-item {
  background-color: #f1f1f1;
  padding: 8px 16px;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
}
#mainBody .nav-container .finder .result .consts-list .consts-list-item:hover {
  background-color: #fec887;
}
#mainBody .nav-container .finder .result .result-block {
  margin-bottom: 10px;
}
#mainBody .nav-container .finder .result .result-block p {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}
#mainBody .nav-container .finder .result .result-block .result-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
#mainBody .nav-container .finder .result .result-block .result-list li {
  width: 100%;
}
#mainBody .nav-container .finder .result .result-block .result-list .result-list-item {
  background-color: #f1f1f1;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
}
#mainBody .nav-container .finder .result .result-block .result-list .result-list-item:hover {
  background-color: #fec887;
}
#mainBody .nav-container .brand {
  width: 300px;
  font-size: 30px;
  font-weight: bolder;
  color: #e9942c;
}
#mainBody .nav-container ul {
  list-style: none;
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0;
  padding: 10px 0;
}
#mainBody .main-layout {
  display: flex;
  height: calc(100% - 64px);
}
#mainBody .main-layout .main-layout-content-block-container {
  flex-grow: 1;
  background-color: #f6f6fa;
  padding: 30px;
  overflow-y: auto;
  padding-bottom: 100px;
}
#mainBody aside {
  background-color: white;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  padding: 30px;
  padding-bottom: 100px;
  overflow: auto;
}
#mainBody aside .main-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
#mainBody aside .main-menu > li {
  margin-bottom: 30px;
}
#mainBody aside .main-menu > li h4 {
  font-weight: 600;
  color: #2a3452;
  margin-bottom: 20px;
}
#mainBody aside .main-menu .main-menu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}
#mainBody aside .main-menu .main-menu-links .main-menu-links-item {
  display: flex;
  gap: 10px;
}
#mainBody aside .main-menu .main-menu-links .main-menu-links-item span {
  width: 20px;
}
#mainBody aside .main-menu .main-menu-links li {
  padding: 4px 10px;
  font-size: 16px;
  position: relative;
}
#mainBody aside .main-menu .main-menu-links li p {
  margin-bottom: 6px;
}
#mainBody aside .main-menu .main-menu-links li a {
  color: #b7bac4 !important;
  text-decoration: none !important;
}
#mainBody aside .main-menu .main-menu-links li a:hover {
  color: #fec887 !important;
}
#mainBody aside .main-menu .main-menu-links li.active::after {
  width: 10px;
  height: calc(100% - 10px);
  left: -30px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  top: 5px;
  position: absolute;
  content: "";
  background-color: #e9942c;
}
#mainBody aside .main-menu .main-menu-links li.active a {
  color: #e9942c !important;
}
#mainBody .user-list-item {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 6px;
  padding-right: 12px;
  border-radius: 4px;
}
#mainBody .user-list-item:hover {
  background-color: #d3d9ff;
}
#mainBody .user-list-item:active {
  background-color: #acb6f1;
}
#mainBody .user-list-item p {
  margin-bottom: 0;
}
#mainBody .user-list-item img {
  border: 1px solid #b8b8b8;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
     object-fit: cover;
}
#mainBody .manage-table {
  border-radius: 10px;
  background-color: white;
  padding: 20px;
}
#mainBody .manage-table .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
#mainBody .manage-table .header .title {
  font-size: 20px;
  margin-bottom: 0;
  padding: 0;
}
#mainBody .manage-table .header ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none !important;
  gap: 20px;
}
#mainBody .manage-table .body a {
  text-decoration: none !important;
}
#mainBody .manage-table .footer .manage-table-pagination {
  display: flex;
  gap: 6px;
}
#mainBody .manage-table .footer .manage-table-pagination button {
  background-color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  font-weight: 500;
}
#mainBody .manage-table .footer .manage-table-pagination button:hover {
  background-color: #fec887;
}
#mainBody .manage-table .footer .manage-table-pagination button.active {
  background-color: #e9942c;
  color: white;
}
#mainBody .white-form-block {
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  max-width: 1000px;
}
#mainBody .white-form-block label {
  min-width: 200px;
  width: 200px;
  margin-right: 30px;
}
#mainBody .white-form-block .form-check-label {
  width: auto !important;
}
#mainBody .toast-container {
  right: 50px;
  top: 110px;
}
#mainBody #root > div > div.main-layout div.ck.ck-reset.ck-editor.ck-rounded-corners > div.ck.ck-editor__main > div {
  height: 200px;
}
#mainBody .dropdown-toggle {
  width: 40px;
}
#mainBody .dropdown-toggle::after {
  display: none !important;
}
#mainBody .application-details-container .application-details-item {
  display: flex;
  padding: 6px 12px;
  margin-bottom: 20px;
  border-radius: 8px;
}
#mainBody .application-details-container .application-details-item:hover {
  background-color: #e7eaff;
}
#mainBody .application-details-container .application-details-item.not-hovered:hover {
  background-color: white !important;
}
#mainBody .application-details-container .application-details-item .title {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  color: grey;
}
#mainBody .application-details-container .application-details-item .value {
  font-weight: 500;
  width: calc(100% - 250px);
}/*# sourceMappingURL=main.css.map */
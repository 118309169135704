#authLayout {
  background: #f6f2eb;
  font-weight: 500;
  font-size: 14px;
  min-height: 100vh;
}
#authLayout .container {
  max-width: 1200px;
}
#authLayout a {
  color: black !important;
  text-decoration: none;
}
#authLayout a:hover {
  text-decoration: underline;
}
#authLayout .btn {
  padding: 8px 16px;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none !important;
  border-radius: 12px;
}
#authLayout .btn.btn-primary {
  background: #fec887;
  color: black;
  border-color: #fec887;
}
#authLayout .btn.btn-primary:hover, #authLayout .btn.btn-primary:active, #authLayout .btn.btn-primary:focus {
  background: #e19e4d !important;
  border-color: #e19e4d;
}
#authLayout .btn.btn-outline-primary {
  background: none;
  color: black;
  border-color: #fec887;
}
#authLayout .btn.btn-outline-primary:hover, #authLayout .btn.btn-outline-primary:active, #authLayout .btn.btn-outline-primary:focus {
  background: #fec887 !important;
  border-color: #fec887;
}
#authLayout .sign-in-container,
#authLayout .sign-up-container,
#authLayout .need-company-container {
  padding-top: 30px;
  min-height: 700px;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/public/images/auth-container-background.png");
}
#authLayout .form-control {
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 12px;
}
#authLayout .input-group input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
#authLayout .input-group button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
#authLayout nav {
  padding: 10px;
}
#authLayout nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#authLayout nav h2 {
  font-weight: bolder;
}
#authLayout nav ul {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;
  margin: 0;
}
#authLayout nav ul .dropdown-menu {
  min-width: auto;
}
#authLayout nav ul .dropdown-menu a:active, #authLayout nav ul .dropdown-menu a:focus {
  color: black;
  background-color: #fec887;
}
#authLayout .white-block {
  background-color: white !important;
  box-shadow: 0px 12px 20px -2px rgba(52, 53, 53, 0.174);
  padding: 40px;
  border-radius: 34px;
}
#authLayout .need-company-container {
  text-align: center;
}
#authLayout .need-company-container .white-block {
  width: 450px;
}
#authLayout .need-company-container .white-block h1 {
  font-size: 28px;
  font-weight: bold;
}
#authLayout .need-company-container .white-block h4 {
  font-size: 18px;
  width: 100%;
}
#authLayout .need-company-container .company-binding-form {
  margin-bottom: 30px;
}
#authLayout .need-company-container .company-binding-form .title {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  gap: 10px;
  align-items: center;
}
#authLayout .need-company-container .company-binding-form .title .line {
  height: 2px;
  width: 20px;
  border-radius: 3px;
  margin-top: 4px;
  background-color: black;
}
#authLayout .sign-up-container {
  display: flex;
  align-items: start;
}
#authLayout .sign-up-container .sign-up-invite {
  margin-bottom: 30px;
}
#authLayout .sign-up-container .sign-up-invite .title {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  gap: 10px;
  align-items: center;
}
#authLayout .sign-up-container .sign-up-invite .title .line {
  height: 2px;
  width: 20px;
  border-radius: 3px;
  margin-top: 4px;
  background-color: black;
}
#authLayout .sign-up-container .form-control {
  margin-bottom: 14px;
}
#authLayout .sign-up-container .form-control:focus {
  border-color: #fec887;
  box-shadow: 0 0 0 0.25rem rgba(253, 165, 13, 0.25);
}
#authLayout .sign-up-container .white-block {
  width: 100%;
  max-width: 460px;
}
#authLayout .sign-up-container .white-block h1 {
  font-size: 28px;
  font-weight: bolder;
  text-align: center;
}
#authLayout .sign-up-container .white-block h4 {
  text-align: center;
  font-size: 18px;
  max-width: 80%;
  margin: 20px auto 40px auto;
}
#authLayout .sign-up-container a {
  color: black !important;
  text-decoration: none;
}
#authLayout .sign-up-container a:hover {
  text-decoration: underline;
}
#authLayout .sign-in-container {
  display: flex;
  align-items: start;
}
#authLayout .sign-in-container .sign-in-with-external {
  margin-bottom: 30px;
}
#authLayout .sign-in-container .sign-in-with-external .title {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  gap: 10px;
  align-items: center;
}
#authLayout .sign-in-container .sign-in-with-external .title .line {
  height: 2px;
  width: 20px;
  border-radius: 3px;
  margin-top: 4px;
  background-color: black;
}
#authLayout .sign-in-container .sign-in-with-external .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
#authLayout .sign-in-container .white-block {
  width: 100%;
  max-width: 460px;
}
#authLayout .sign-in-container .white-block h1 {
  font-size: 28px;
  font-weight: bolder;
  text-align: center;
}
#authLayout .sign-in-container .white-block h4 {
  text-align: center;
  font-size: 18px;
  max-width: 80%;
  margin: 20px auto 40px auto;
}
#authLayout .sign-in-container .form-control {
  margin-bottom: 14px;
}
#authLayout .sign-in-container .sign-in-password-input-container {
  position: relative;
}
#authLayout .sign-in-container .sign-in-password-input-container button {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  background: none;
  border: none !important;
  font-size: 12px;
  outline: none !important;
}
#authLayout .sign-in-container .sign-in-password-input-container button:hover {
  text-decoration: underline;
}
#authLayout .sign-in-container .sign-in-problem-text {
  font-weight: 600;
  margin-bottom: 30px;
}
#authLayout .sign-in-container .sign-in-problem-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
#authLayout footer {
  margin-top: 30px;
  padding-bottom: 100px;
}
#authLayout footer p {
  max-width: 450px;
}

::-ms-reveal {
  display: none;
}/*# sourceMappingURL=auth.css.map */
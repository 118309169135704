$mainBg: #f6f2eb;
$btnBg: #fec887;
$btnHoverBg: #e19e4d;

#authLayout {
  background: $mainBg;
  font-weight: 500;
  font-size: 14px;
  min-height: 100vh;

  .container {
    max-width: 1200px;
  }

  a {
    color: black !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn {

    padding: 8px 16px;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none !important;
    border-radius: 12px;

    &.btn-primary {
      background: $btnBg;
      color: black;
      border-color: $btnBg;

      &:hover,
      &:active,
      &:focus {
        background: $btnHoverBg !important;
        border-color: $btnHoverBg;
      }
    }

    &.btn-outline-primary {
      background: none;
      color: black;
      border-color: $btnBg;

      &:hover,
      &:active,
      &:focus {
        background: $btnBg !important;
        border-color: $btnBg;
      }
    }
  }

  .sign-in-container,
  .sign-up-container,
  .need-company-container {
    padding-top: 30px;
    min-height: 700px;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/public/images/auth-container-background.png');
  }

  .form-control {
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 12px;
  }

  .input-group {
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    button {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  nav {
    padding: 10px;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      font-weight: bolder;

    }

    ul {
      display: flex;
      list-style: none;
      gap: 20px;
      padding: 0;
      margin: 0;


      .dropdown-menu {
        min-width: auto;

        a {


          &:active,
          &:focus {
            color: black;
            background-color: $btnBg;
          }
        }
      }
    }
  }

  .white-block {
    background-color: white !important;
    box-shadow: 0px 12px 20px -2px rgba(52, 53, 53, 0.174);
    padding: 40px;
    border-radius: 34px;

  }

  .need-company-container {
    text-align: center;

    .white-block {
      width: 450px;

      h1 {
        font-size: 28px;
        font-weight: bold;
      }

      h4 {
        font-size: 18px;
        width: 100%;
      }
    }

    .company-input-container {}

    .company-binding-form {
      margin-bottom: 30px;

      .title {
        display: flex;
        justify-content: center;
        margin: 30px 0;
        gap: 10px;
        align-items: center;

        .line {
          height: 2px;
          width: 20px;
          border-radius: 3px;
          margin-top: 4px;
          background-color: black;
        }
      }
    }
  }

  .sign-up-container {
    display: flex;
    align-items: start;

    .sign-up-invite {
      margin-bottom: 30px;

      .title {
        display: flex;
        justify-content: center;
        margin: 30px 0;
        gap: 10px;
        align-items: center;

        .line {
          height: 2px;
          width: 20px;
          border-radius: 3px;
          margin-top: 4px;
          background-color: black;
        }
      }
    }

    .form-control {
      margin-bottom: 14px;

      &:focus {
        border-color: $btnBg;
        box-shadow: 0 0 0 0.25rem rgba(253, 165, 13, 0.25);
      }
    }

    .white-block {
      width: 100%;
      max-width: 460px;

      h1 {
        font-size: 28px;
        font-weight: bolder;
        text-align: center;
      }

      h4 {
        text-align: center;
        font-size: 18px;
        max-width: 80%;
        margin: 20px auto 40px auto;
      }
    }

    a {
      color: black !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .sign-in-container {
    display: flex;
    align-items: start;



    .sign-in-with-external {
      margin-bottom: 30px;

      .title {
        display: flex;
        justify-content: center;
        margin: 30px 0;
        gap: 10px;
        align-items: center;

        .line {
          height: 2px;
          width: 20px;
          border-radius: 3px;
          margin-top: 4px;
          background-color: black;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .white-block {
      width: 100%;
      max-width: 460px;

      h1 {
        font-size: 28px;
        font-weight: bolder;
        text-align: center;
      }

      h4 {
        text-align: center;
        font-size: 18px;
        max-width: 80%;
        margin: 20px auto 40px auto;
      }
    }

    .form-control {
      margin-bottom: 14px;
    }

    .sign-in-password-input-container {
      position: relative;

      button {
        position: absolute;
        right: 12px;
        top: 0;
        bottom: 0;
        background: none;
        border: none !important;
        font-size: 12px;
        outline: none !important;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .sign-in-problem-text {
      font-weight: 600;
      margin-bottom: 30px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  footer {
    margin-top: 30px;
    padding-bottom: 100px;

    p {
      max-width: 450px;
    }
  }
}

::-ms-reveal {
  display: none;
}